import React from 'react'
import Accordion from './Accordion'

const Director = (props) => (
    <div className="md:flex items-center mx-auto border-t-2 py-8 sm:py-10 space-y-8 sm:space-y-12 md:space-y-0 sm:space-x-7 md:space-x-10 lg:space-x-14 xl:space-x-16">
        <div className="md:w-1/4 lg:w-1/3 pr-6 md:pr-0">
            <img
                data-aos="fade-right"
                data-aos-offset="400"
                data-aos-duration="1800"
                className="w-full"
                src={props.img}
                alt={props.imgAlt}
            />
        </div>
        <div className="font-arima md:w-3/4 lg:w-2/3 text-left sm:py-2 space-y-1">
            <div className="space-y-1 md:flex justify-between">
                <div>
                    <h2 className="text-2xl font-arima text-darkblue sm:text-3xl md:text-2xl lg:text-3xl">
                        {props.name}
                    </h2>
                    <h4 className="font-arima text-lightblue text-sm sm:text-xl md:text-sm xl:text-xl">
                        {props.title}
                    </h4>
                </div>
                {props.linkedin && (
                    <div className="pt-2 md:pt-0">
                        <a
                            href={props.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block text-sm rounded-full px-4 sm:px-6 md:px-8 lg:px-12 py-1 md:py-2 bg-darkblue text-white"
                        >
                            {props.linkedinButton}
                        </a>
                    </div>
                )}
            </div>
            <div
                className="hidden sm:block font-poppins text-lightblue text-xs sm:text-sm md:text-xs lg:text-sm pt-2 xl:text-sm space-y-4"
                dangerouslySetInnerHTML={{ __html: props.paragraph }}
            />
            <div className="sm:hidden pt-6">
                <Accordion paragraph={props.paragraph} />
            </div>
        </div>
    </div>
)

export default Director
