import React from "react";
import I18nContext from "../lib/i18n-context";
import Director from "../components/Director";

const OurTeam = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourTeam;

  return (
    <section className="w-full pt-6 md:pt-10 lg:pt-16">
      <div className="container max-w-screen-xl px-10 py-6 lg:px-16">
        <h2 className="font-arima text-2xl text-darkblue sm:text-2xl lg:text-3xl">
          {data.title}
        </h2>
        <div className="w-full py-4 space-y-6">
          {data.team.map((item, index) => (
            <Director
              key={index}
              img={item.img}
              imgAlt={item.imgAlt}
              name={item.name}
              title={item.title}
              paragraph={item.paragraph}
              linkedin={item.linkedin}
              linkedinButton={item.linkedinButton}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
