import React from "react";

import I18nContext from "../lib/i18n-context";

const AboutUsTextImage = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.aboutUsTextImage;

  return (
    <section className="w-full">
      <div className="container max-w-screen-xl flex flex-col items-center justify-center sm:flex-row py-12 lg:py-20 px-6 md:px-12 lg:px-20 xl:px-24 space-y-10 sm:space-y-0">
        <div className="flex-1">
          <img
            className="w-full"
            src="/assets/asian-map-1.jpg"
            alt="Graphic of asian map"
          />
        </div>
        <div className="flex-1 w-full space-y-6">
          <div className="text-left font-arima px-4 sm:px-0 sm:pl-6 md:pl-20 lg:pl-28">
            <div className="text-md sm:text-md lg:text-xl font-arima space-y-4 lg:space-y-6">
              <h3>
                {data.title}
              </h3>
              <p className="font-poppins">
               {data.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsTextImage;
