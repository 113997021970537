import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import NavBar from "../components/nav";
import Footer from "../components/Footer";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import Seo from "../components/Seo";
import AboutUsBanner from "../components/AboutUsBanner";
import AboutUsStatement from "../components/AboutUsStatement";
import AboutUsTextImage from "../components/AboutUsTextImage";
import OurTeam from "../components/OurTeam";
import OurValues from '../components/OurValues'
import AboutUsWhatWeDo from '../components/AboutUsWhatWeDo'

const AboutUs = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();

    $(document).ready(function() {
      $("a").on("click", function(event) {
        if (this.hash !== "") {
          event.preventDefault();

          let hash = this.hash;

          $("html, body").animate(
            {
              scrollTop: $(hash).offset().top,
            },
            800,
            function() {
              window.location.hash = hash;
            }
          );
        }
      });
    });
  }, []);

  return (
    <Layout>
      <Seo
        title={"About us"}
        description={
          "Our team can equip you to enter the Chinese market, optimise your existing position, create resonance with key consumers."
        }
      />
      <NavBar
        navClass={"white-navbar"}
        logo={"/assets/main-logo-blue.svg"}
        class={"text-darkblue border-darkblue"}
      />
      <main>
        <div className="mt-20 bg-darkblue text-white">
          <AboutUsBanner />
          <AboutUsStatement />
          <AboutUsTextImage />
        </div>
        <OurTeam />
        <OurValues />
        {/* WHAT WE DO */}
        <AboutUsWhatWeDo />
      </main>
      <Footer />
    </Layout>
  );
};

export default AboutUs;

export const pageQuery = graphql`
  query AboutUsQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
