import React, { useState, useRef } from "react";
import Chevron from "./Chevron";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <div className="space-y-2">
      <button
        className={`accordion ${setActive} flex`}
        onClick={toggleAccordion}
      >
        <p className="text-left text-lg font-arima text-darkblue pr-6 font-semibold">
          Read bio
        </p>
        <Chevron className={`${setRotate} pt-1`} width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div
          className="font-poppins text-lightblue text-xs sm:text-sm md:text-xs lg:text-sm pt-2 xl:pr-28 xl:text-sm space-y-4"
          dangerouslySetInnerHTML={{ __html: props.paragraph }}
        />
      </div>
    </div>
  );
}

export default Accordion;
