import React from "react";
import I18nContext from "../lib/i18n-context";

const AboutUsStatement = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.aboutUsStatement;

  return (
    <section
      id="main"
      className="container max-w-screen-xl px-10 py-16 lg:px-16"
    >
      <div className="text-center pb-6 md:pb-10">
        <h3 className="font-arima text-xl md:text-2xl lg:text-3xl max-w-4xl mx-auto lg:px-8">
          {data.statement}
        </h3>
      </div>
      <div className="grid grid-cols-2 gap-6 lg:gap-10 md:grid-cols-4 place-items-center">
        <div>
          <a
            href="https://www.grenade.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/grenade-logo.svg" alt="Grenade company logo" />
          </a>
        </div>
        <div>
          <a
            href="https://www.ecotone.bio/fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/bjorg.svg" alt="BJORG company logo" />
          </a>
        </div>
        <div>
          <a
            href="https://www.nandos.co.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/Nandos.png" alt="Nandos company logo" />
          </a>
        </div>
        <div>
          <a
            href="https://www.salus-haus.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/floradix-logo.svg" alt="Floradix company logo" />
          </a>
        </div>
      </div>
      <div className="text-center pt-6 md:pt-10">
        <h3 className="font-arima text-xl md:text-2xl lg:text-3xl max-w-4xl mx-auto lg:px-8">
          {data.secondStatement && (data.secondStatement)}
        </h3>
      </div>
    </section>
  );
};

export default AboutUsStatement;
